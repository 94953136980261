import { render, staticRenderFns } from "./merchantList.vue?vue&type=template&id=474b3220&scoped=true&"
import script from "./merchantList.vue?vue&type=script&lang=js&"
export * from "./merchantList.vue?vue&type=script&lang=js&"
import style0 from "./merchantList.vue?vue&type=style&index=0&id=474b3220&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "474b3220",
  null
  
)

export default component.exports