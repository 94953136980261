<template>
    <el-main>
        <el-form class="el-form-search" label-width="120px">
            <el-form-item label="商家信息：">
                <el-input size="small" v-model="searchForm.contact_name" placeholder="请输入用户姓名"></el-input>
            </el-form-item>
            <el-form-item label="商家电话：">
                <el-input size="small" v-model="searchForm.store_phone" placeholder="请输入商家电话"></el-input>
            </el-form-item>
            <el-form-item label="服务商姓名：">
                <el-input size="small" v-model="searchForm.name" placeholder="请输入服务商姓名"></el-input>
            </el-form-item>
            <el-form-item label="服务商电话：">
                <el-input size="small" v-model="searchForm.mobile" placeholder="请输入服务商电话"></el-input>
            </el-form-item>
            <el-form-item label="店铺名称：">
                <el-input size="small" v-model="searchForm.store_name" placeholder="请输入店铺名称"></el-input>
            </el-form-item>
            <el-form-item label="所属区域：">
                <el-cascader v-model="searchForm.area_id" :props="{ value: 'id', label: 'name', children: '_child' }"
                    placeholder="请选择或搜索" clearable filterable :options="areaList" size="small"></el-cascader>
            </el-form-item>
            <el-form-item label="是否已激励：">
                <el-select v-model="searchForm.is_incentive" size="small" placeholder="请选择">
                    <el-option label="全部" :value="-1"></el-option>
                    <el-option label="未激励" :value="0"></el-option>
                    <el-option label="已激励" :value="1"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="话费分佣状态：">
                <el-select v-model="searchForm.is_mercode" size="small" placeholder="请选择">
                    <el-option label="全部" :value="-1"></el-option>
                    <el-option label="未开启" :value="0"></el-option>
                    <el-option label="已开启" :value="1"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="通过日期：">
                <el-date-picker size="small" v-model="searchForm.time" type="daterange" range-separator="至"
                    start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
            </el-form-item>
            <el-form-item label="是否统计：">
                <el-select v-model="searchForm.is_statistics" size="small" placeholder="请选择">
                    <el-option label="全部" :value="0"></el-option>
                    <el-option label="统计" :value="1"></el-option>
                    <el-option label="不统计" :value="-1"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label=" " label-width="25px">
                <el-button type="primary" size="small" @click="search">搜索</el-button>
                <el-button size="small" @click="exportEx">导出</el-button>
                <!-- <el-upload
          :on-change="importEx"
          ref="input"
          action="#"
          :show-file-list="false"
          :auto-upload="false"
          type="file"
          class="upload"
          accept="'.xlsx','.xls'"
          :headers="headers"
          style="display: inline; margin: 0 10px"
        >
          <el-button type="primary" size="small">导入商户号</el-button>
        </el-upload> -->
                <el-button size="small" @click="showImport = !0">导入商户号</el-button>
                <el-button type="text" size="small" @click="clearSearch">清空搜索条件</el-button>
            </el-form-item>
        </el-form>
        <div class="total-num">
            <div>筛选后总收益：{{ total_amount }}</div>
            <div>筛选后总余额：{{ amount }}</div>
            <div>筛选后总提现金额：{{ withdrawal_amount }}</div>
        </div>
        <el-table :data="list" ref="goodsList" :header-cell-style="{ 'background-color': '#F8F9FA' }" row-key="id">
            <template slot="empty">
                <No />
            </template>
            <el-table-column prop="contact_name" label="商家信息" width="180" align="center">
                <template v-slot="{ row }">
                    <userInfo :name="row.contact_name" :avatar="row.store_logo"></userInfo>
                </template>
            </el-table-column>
            <el-table-column prop="store_phone" label="商家电话" align="center" width="120"></el-table-column>
            <el-table-column prop="area" label="所属区域" align="center" width="100">
                <template v-slot="{ row }">
                    {{ row.area || '--' }}
                </template>
            </el-table-column>
            <el-table-column prop="name" label="服务商姓名" align="center" width="100"></el-table-column>
            <el-table-column prop="mobile" label="服务商电话" align="center" width="120"></el-table-column>
            <el-table-column prop="store_name" label="店铺名称" align="center" width="120"></el-table-column>
            <el-table-column prop="valid_connections" label="店铺总计连接次数" align="center" width="80"></el-table-column>
            <el-table-column label="是否已激励" align="center" width="100">
                <template v-slot="{ row }">{{ row.is_incentive ? '已激励' : '未激励' }}</template>
            </el-table-column>
            <el-table-column prop="earnings" label="收益比例" align="center">
                <template v-slot="{ row }">{{ row.earnings }}%</template>
            </el-table-column>
            <el-table-column prop="total_amount" align="center">
                <template #header>
                    <div @click="sortList('total_amount')" class="sortList">
                        <div>总收益</div>
                        <i :style="{ color: orderBy == 'total_amount' ? '#409EFF' : '' }"
                            class="el-icon-caret-bottom"></i>
                    </div>
                </template>
                <template v-slot="{ row }">￥{{ row.total_amount }}</template>
            </el-table-column>
            <el-table-column prop="amount" align="center">
                <template #header>
                    <div @click="sortList('amount')" class="sortList">
                        <div>当前余额</div>
                        <i :style="{ color: orderBy == 'amount' ? '#409EFF' : '' }" class="el-icon-caret-bottom"></i>
                    </div>
                </template>
                <template v-slot="{ row }">￥{{ row.amount }}</template>
            </el-table-column>
            <el-table-column prop="withdrawal_amount" width="120px" align="center">
                <template #header>
                    <div @click="sortList('withdrawal_amount')" class="sortList">
                        <div>累计提现金额</div>
                        <i :style="{ color: orderBy == 'withdrawal_amount' ? '#409EFF' : '' }"
                            class="el-icon-caret-bottom"></i>
                    </div>
                </template>
                <template v-slot="{ row }">￥{{ row.withdrawal_amount }}</template>
            </el-table-column>
            <el-table-column label="通过时间" align="center" width="100">
                <template v-slot="{ row }">{{ getDateformat(row.update_time) }}</template>
            </el-table-column>
            <el-table-column label="话费分佣状态" align="center" width="110">
                <template v-slot="{ row }">
                    <div :style="{ color: row.mercode ? '#413a96' : '#8b4446' }">{{ row.mercode ? '已开启' : '未开启' }}</div>
                </template>
            </el-table-column>
            <el-table-column label="操作" align="center" fixed="right" width="230">
                <template v-slot="{ row }">
                    <el-button @click="seeDetailed(row)" type="text" size="small">查看明细</el-button>
                    <el-button @click="authFn(row)" type="text" size="small">授权小程序</el-button>
                    <el-button @click="changePrivate(row)" type="text" size="small">{{ row.is_jump_private ? '取消私域' :
                    '开启私域'
                        }}</el-button>
                    <el-button @click="black(row)" type="text" size="small">{{ row.is_black ? '恢复' : '拉黑' }}</el-button>
                    <el-button @click="close(row)" type="text" size="small">{{ row.is_close ? '开启' : '关闭' }}</el-button>
                    <el-button type="text" size="small" @click="openHuaFei(row)">{{ row.mercode ? '查看话费分佣' : '开启话费分佣'
                        }}</el-button>
                    <el-button v-if="row.is_execute" type="text" size="small"
                        @click="PerformAction(row)">修复首页</el-button>
                    <el-button @click="statistics(row)" type="text" size="small" style="color: #f56c6c">{{
                    row.is_statistics
                        == 1 ? '统计' : '不统计' }}</el-button>
                    <el-button @click="handleIncentive(row.qrcode_id)" type="text" size="small"
                        v-if="!row.is_incentive">有效激励奖励</el-button>
                    <el-button @click="handleShopCreate(row)" type="text" size="small">{{ row.is_sync_yun_hao ? '已' :
                    '' }}开通云昊账户</el-button>
                    <el-button @click="handleAccountAppid(row)" type="text" size="small">绑定站点</el-button>
                    <el-button @click="handleStoreUsers(row.id)" type="text" size="small">站点账密</el-button>
                    <el-button @click="authFn(row)" type="text" size="small">配置官方支付</el-button>
                    <el-button @click="handleTopup(row)" type="text" size="small">配送充值</el-button>
                </template>
            </el-table-column>
        </el-table>
        <Paging :total="total_number" :page="searchForm.page" :pageNum="searchForm.rows" @updatePageNum="updateData">
        </Paging>
        <el-dialog title="查看明细" :visible.sync="showDetailed" width="780px">
            <el-table :data="balanceList" :header-cell-style="{ 'background-color': '#F8F9FA' }">
                <template slot="empty">
                    <No />
                </template>
                <el-table-column prop="create_time" label="生成时间" align="center">
                    <template v-slot="{ row }">
                        {{ getDateformat(row.create_time) }}
                    </template>
                </el-table-column>
                <el-table-column prop="type" label="收益支出" align="center">
                    <template v-slot="{ row }">
                        {{ dealStatus(Number(row.type)) }}
                    </template>
                </el-table-column>
                <el-table-column prop="amount" label="预估收益" align="center">
                    <template v-slot="{ row }">{{ row.income_type == 1 ? '+' : '-' }}{{ row.amount }}</template>
                </el-table-column>
            </el-table>
            <Paging :total="balance_total" :page="balance_page" :pageNum="balance_rows"
                @updatePageNum="balanceUpdateData">
            </Paging>
        </el-dialog>
        <!-- 开启话费分佣 -->
        <el-dialog title="提示" :visible.sync="HuaFeiFlag" width="600px">
            <el-form ref="form" label-width="80px">
                <el-form-item label="商户号">
                    <el-input v-if="!HuaFeiData.mercode" v-model="mercode"></el-input>
                    <div v-else>{{ HuaFeiData.mercode }}</div>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer" v-if="!HuaFeiData.mercode">
                <el-button @click="HuaFeiFlag = false">取 消</el-button>
                <el-button type="primary" @click="sureHuaFei">确 定</el-button>
            </span>
        </el-dialog>
        <!-- 导入商品 -->
        <el-dialog title="导入商品号" :visible.sync="showImport" width="550px">
            <el-link type="primary" :href="templatePath">下载导入模板</el-link>
            <el-input class="upload" v-model="filePath">
                <el-button slot="append">
                    上传文件
                    <input type="file" ref="inputer" @change="getFile" />
                </el-button>
            </el-input>
            <span slot="footer" class="dialog-footer">
                <el-button @click="showImport = !1">取 消</el-button>
                <el-button type="primary" @click="confirmImport">确 定</el-button>
            </span>
        </el-dialog>
        <!-- 绑定站点 -->
        <el-dialog title="绑定站点" :visible.sync="AccountAppidFlag" width="500px">
            <el-form label-width="100px">
                <el-form-item label="站点APPID" required>
                    <el-input v-model="AccountAppid.account_appid"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="AccountAppidFlag = false">取 消</el-button>
                <el-button type="primary" @click="setAccountAppid">确 定</el-button>
            </span>
        </el-dialog>
        <!-- 站点账密 -->
        <el-dialog title="站点账密" :visible.sync="StoreUsersFlag" width="500px">
            <el-form label-width="100px">
                <el-form-item label="站点账号：">
                    <div>{{ StoreUsers.staff_account }}</div>
                </el-form-item>
                <el-form-item label="站点密码：">
                    <div>{{ StoreUsers.staff_password }}</div>
                </el-form-item>
            </el-form>
        </el-dialog>
        <!-- 配送充值 -->
        <el-dialog title="配送充值" :visible.sync="topupFlag" width="600px">
            <el-form label-width="120px">
                <el-form-item label="商家店铺名称：">
                    <div>{{ store_name }}</div>
                </el-form-item>
                <el-form-item label="配送费余额：">
                    <div>￥{{ yun_hao_send_amount }}</div>
                </el-form-item>
                <el-form-item label="充值金额：">
                    <el-input v-model="topup_form.amount">
                        <template slot="append">元</template>
                    </el-input>
                    <el-button type="primary" style="margin-top: 10px;"
                        @click="$router.push({ path: '/set/deliveryCharge', query: { store_name } })">查看充值明细</el-button>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="topupFlag = false">取 消</el-button>
                <el-button type="primary" @click="comfirmTopup">确 定</el-button>
            </span>
        </el-dialog>
    </el-main>
</template>

<script>
import config from '@/util/config';
import userInfo from '@/components/userInfo';
import Paging from '@/components/paging';

import { getDateformat } from '@/util/getDate';
import _ from 'lodash';
import { inspectPrice } from '@/util/verification';

export default {
    components: {
        userInfo,
        Paging,
    },
    data () {
        return {
            searchForm: {
                page: 1,
                rows: 10,
                contact_name: '',
                store_phone: '',
                name: '',
                mobile: '',
                store_name: '',
                province_id: '',
                city_id: '',
                area_id: [],
                is_incentive: -1,
                is_mercode: -1,
                time: [],
                is_statistics: 0,
            },
            id: '',
            list: [],
            areaList: [],
            balanceList: [],
            total_number: 0,
            showDetailed: !1,
            balance_page: 1,
            balance_rows: 10,
            balance_total: 0,
            // 排序方式
            orderBy: '',
            // 是否开启话费分佣
            HuaFeiFlag: false,
            HuaFeiData: {},
            mercode: '',
            total_amount: '',
            amount: '',
            withdrawal_amount: '',
            showImport: !1,
            formData: '',
            filePath: '',
            templatePath: config.baseurl + '/' + 'Upload/import/商品号导入模板.xlsx',

            AccountAppid: {
                id: '',
                account_appid: ''
            },
            AccountAppidFlag: false,
            StoreUsersFlag: false,
            StoreUsers: '',
            topupFlag: false,
            topup_form: {
                id: '',
                amount: ''
            },
            yun_hao_send_amount: 0,
            store_name: ''
        };
    },
    created () {
        this.getList();
        this.getArea();
    },
    methods: {
        dealStatus (type) {
            switch (type) {
                case 1:
                    return 'wifi';
                    break;
                case 2:
                    return '第三方活动';
                    break;
                case 3:
                    return '扫码点餐';
                    break;
                case 4:
                    return '提现';
                    break;
                case 5:
                    return '全真支付';
                    break;
                case 6:
                    return '连接激励奖励';
                    break;
                case 7:
                    return '消费激励奖励';
                    break;
                case 8:
                    return 'CPS商品分润';
                    break;
                case 10:
                    return '话费';
                    break;
                case 20:
                    return '直充';
                    break;
                case 30:
                    return '吃喝卡券';
                    break;
                case 40:
                    return '电费';
                    break;
                case 50:
                    return '广告金';
                    break;
                case 60:
                    return '小程序上线奖励';
                    break;
                case 70:
                    return '点餐流水奖励';
                    break;
                case 80:
                    return '配送抽佣奖励';
                    break;
                case 90:
                    return '贴码视频奖励';
                    break;
                case 100:
                    return '寄快递';
                    break;
                default:
                    return '未知状态';
                    break;
            }
        },
        PerformAction (data) {
            this.$confirm('此操作将商家执行完成操作, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    return this.$axios.post(this.$api.serviceProvider.execute, {
                        qrcode_id: data.qrcode_id,
                    });
                })
                .then(res => {
                    if (res.code == 0) {
                        this.$message.success('操作成功');
                        data.is_execute = 0;
                    } else {
                        this.$message.error(res.msg);
                    }
                })
                .catch(() => { });
        },
        // 导出数据成excel
        exportEx () {
            let searchForm = this.dealSearchData();
            delete searchForm.page;
            delete searchForm.rows;
            this.$axios
                .post(this.$api.serviceProvider.storeExport, searchForm)
                .then(res => {
                    if (res.code == 0) {
                        this.open();
                    }
                })
                .catch(err => {
                    console.log(err);
                });

            //this.$axios.post(this.$api.serviceProvider.storeExport, searchForm).then(res => {
            //    if (res.code == 0) {
            //        let path = config.baseurl + '/' + res.result;
            //        let a = document.createElement('a');
            //        a.href = path;
            //        a.id = 'download';
            //        document.body.appendChild(a);
            //        a.click();
            //        let aDom = document.getElementById('download');
            //        document.body.removeChild(aDom);
            //    } else {
            //        this.$message.error(res.msg);
            //    }
            //});
        },
        open () {
            this.$alert('导出中，请耐心等待，稍后跳转到导出文件中下载', '提示', {
                confirmButtonText: '确定',
                callback: action => {
                }
            });
        },
        getFile () {
            let files = this.$refs.inputer.files[0];
            let formData = new FormData();
            formData.append('file', files);
            this.filePath = files.name;
            this.formData = formData;
        },
        // 导入商户号
        confirmImport () {
            if (!this.formData) return this.$message.warning('请先上传文件');
            this.$axios
                .uploadFile(this.$api.serviceProvider.storeImport, this.formData).then(res => {
                    if (res.code == 0) {
                        this.showImport = !1;
                        this.$message.success('商品号导入成功');
                        this.getList();
                    } else {
                        this.$message.error(res.msg);
                    }
                });
        },
        // 更改私域状态
        changePrivate (data) {
            this.$axios
                .post(this.$api.serviceProvider.isPrivate, {
                    is_jump_private: data.is_jump_private ? 0 : 1,
                    id: data.id,
                })
                .then(res => {
                    if (res.code == 0) {
                        data.is_jump_private = data.is_jump_private ? 0 : 1;
                    } else {
                        this.$message.error(res.msg);
                    }
                });
        },
        statistics (row) {
            this.$axios
                .post(this.$api.serviceProvider.isStatisticsStore, {
                    id: row.id,
                    is_statistics: row.is_statistics == 1 ? -1 : 1,
                })
                .then(res => {
                    if (res.code == 0) {
                        row.is_statistics = row.is_statistics == 1 ? -1 : 1;
                    } else {
                        this.$message.error(res.msg);
                    }
                });
        },
        authFn (data) {
            this.$router.push({
                path: '/extension/gatherTweeters/authorizationWx',
                query: {
                    id: data.id,
                },
            });
        },
        getArea () {
            this.$axios.get(this.$api.set.area, { tree: 1, level: 2 }).then(res => {
                if (res.code == 0) {
                    this.areaList = res.result.list;
                }
            });
        },
        getDateformat (val) {
            return getDateformat(val);
        },
        search () {
            this.searchForm.page = 1;
            this.getList();
        },
        clearSearch () {
            this.searchForm = {
                page: 1,
                rows: 10,
                contact_name: '',
                store_phone: '',
                name: '',
                mobile: '',
                store_name: '',
                province_id: '',
                city_id: '',
                area_id: [],
                is_incentive: -1,
                is_mercode: -1,
                time: [],
                is_statistics: 0,
            };
            this.orderBy = '';
            this.getList();
        },
        updateData (val, status) {
            if (status == 0) {
                this.searchForm.rows = val;
                this.getList();
            } else {
                this.searchForm.page = val;
                this.getList();
            }
        },
        balanceUpdateData (val, status) {
            if (status == 0) {
                this.balance_rows = val;
            } else {
                this.balance_page = val;
            }
            this.getBalanceList();
        },
        getBalanceList () {
            this.$axios
                .post(this.$api.serviceProvider.BalanceLog, {
                    page: this.balance_page,
                    rows: this.balance_rows,
                    identity_type: 2,
                    store_id: this.id,
                })
                .then(res => {
                    if (res.code == 0) {
                        this.showDetailed = !0;
                        this.balanceList = res.result.list;
                        this.balance_total = res.result.total_number;
                    } else {
                        this.$message.error(res.msg);
                    }
                });
        },
        seeDetailed (row) {
            this.id = row.id;
            this.balance_page = 1;
            this.getBalanceList();
        },
        black (row) {
            if (row.is_black) {
                this.$axios
                    .post(this.$api.serviceProvider.storeBlack, {
                        id: row.qrcode_id,
                        is_black: 0,
                    })
                    .then(res => {
                        if (res.code == 0) {
                            row.is_black = 0;
                            this.$message.success('已恢复');
                        } else {
                            this.$message.error(res.msg);
                        }
                    });
            } else {
                this.$confirm('请确认是否拉黑该商家？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }).then(() => {
                    this.$axios
                        .post(this.$api.serviceProvider.storeBlack, {
                            id: row.qrcode_id,
                            is_black: 1,
                        })
                        .then(res => {
                            if (res.code == 0) {
                                row.is_black = 1;
                                this.$message.success('已拉黑');
                            } else {
                                this.$message.error(res.msg);
                            }
                        });
                });
            }
        },
        close (row) {
            if (row.is_close) {
                this.handleClose(row)
            } else {
                this.$confirm('请确认是否关闭该商家的服务？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }).then(() => {
                    this.handleClose(row)
                });
            }
        },
        handleClose (row) {
            this.$axios
                .post(this.$api.serviceProvider.storeClose, {
                    id: row.qrcode_id,
                    is_close: row.is_close ? 0 : 1,
                })
                .then(res => {
                    if (res.code == 0) {
                        row.is_close = row.is_close ? 0 : 1;
                        this.$message.success(row.is_close ? '已关闭' : '已开启');
                    } else {
                        this.$message.error(res.msg);
                    }
                });
        },
        sortList (key) {
            this.orderBy = this.orderBy == key ? '' : key;
            this.searchForm.page = 1;
            this.getList();
        },
        dealSearchData () {
            let searchForm = _.cloneDeep(this.searchForm);
            if (searchForm.area_id.length) {
                searchForm.province_id = searchForm.area_id[0];
                searchForm.city_id = searchForm.area_id[1];
                searchForm.area_id = searchForm.area_id[2];
            }
            if (this.orderBy) {
                searchForm.order = this.orderBy;
            } else {
                delete searchForm.order;
            }
            if (searchForm.is_incentive == -1) {
                delete searchForm.is_incentive;
            }
            if (searchForm.is_mercode == -1) {
                delete searchForm.is_mercode;
            }
            delete searchForm.time;
            if (this.searchForm.time?.length) {
                searchForm.start_time = this.searchForm.time[0].getTime() / 1000;
                searchForm.end_time = this.searchForm.time[1].getTime() / 1000;
                if (searchForm.start_time == searchForm.end_time) searchForm.end_time = searchForm.end_time + 86400;
            }
            return searchForm;
        },
        getList () {
            let searchForm = this.dealSearchData();
            this.$axios.post(this.$api.set.storeList, searchForm).then(res => {
                if (res.code == 0) {
                    this.list = res.result.list;
                    this.total_number = res.result.total_number;
                    this.total_amount = res.result.total_amount || 0.0;
                    this.amount = res.result.amount || 0.0;
                    this.withdrawal_amount = res.result.withdrawal_amount || 0.0;
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        // 开启话费分佣
        openHuaFei (data) {
            this.HuaFeiData = data;
            this.HuaFeiFlag = true;
        },
        sureHuaFei () {
            if (this.mercode == '') {
                this.$message.warning('请填写商户号');
                return;
            }
            this.$axios
                .post(this.$api.serviceProvider.setMercode, {
                    id: this.HuaFeiData.id,
                    mercode: this.mercode,
                })
                .then(res => {
                    if (res.code == 0) {
                        this.$message.success('设置成功');
                        this.HuaFeiData.mercode = this.mercode;
                        this.mercode = '';
                        this.HuaFeiFlag = false;
                    } else {
                        this.$message.error(res.msg);
                    }
                });
        },
        handleIncentive (qrcode_id) {
            this.$confirm('有效激励奖励?', '提示', {
                confirmButtonText: '是',
                cancelButtonText: '否',
                type: 'warning',
            })
                .then(() => {
                    this.$axios
                        .post(this.$api.serviceProvider.movementMerch, { qrcode_id })
                        .then(res => {
                            if (res.code == 0) {
                                this.$message.success('设置成功');
                                this.getList()
                            } else {
                                this.$message.error(res.msg);
                            }
                        });
                })
        },
        handleShopCreate (row) {
            if (!row.is_sync_yun_hao) {
                this.$axios
                    .post(this.$api.serviceProvider.shopCreate, { id: row.id })
                    .then(res => {
                        if (res.code == 0) {
                            this.$message.success('同步成功');
                            this.getList()
                        } else {
                            this.$message.error(res.msg);
                        }
                    });
            }
        },
        handleAccountAppid (row) {
            this.AccountAppid = {
                id: row.id,
                account_appid: row.account_appid
            }
            this.AccountAppidFlag = true
        },
        setAccountAppid () {
            this.$axios
                .post(this.$api.serviceProvider.setAccountAppid, this.AccountAppid)
                .then(res => {
                    if (res.code == 0) {
                        this.$message.success('绑定成功');
                        this.AccountAppidFlag = false
                        this.getList()
                    } else {
                        this.$message.error(res.msg);
                    }
                });
        },
        handleStoreUsers (id) {
            this.$axios
                .post(this.$api.store.StoreUsersinfo, {
                    store_id: id
                })
                .then(res => {
                    if (res.code == 0) {
                        this.StoreUsersFlag = true
                        this.StoreUsers = res.result
                    } else {
                        this.$message.error(res.msg);
                    }
                });
        },
        handleTopup (row) {
            this.yun_hao_send_amount = row.yun_hao_send_amount
            this.topup_form.id = row.id
            this.store_name = row.store_name
            this.topupFlag = true
        },
        comfirmTopup () {
            if (!inspectPrice(this.topup_form.amount)) {
                return this.$message.warning('请输入价格正确格式');
            }
            if (this.topup_form.amount * 1 < 10) {
                return this.$message.warning('10元起充');
            }
            this.$axios
                .post(this.$api.serviceProvider.rechargeFreight, this.topup_form)
                .then(res => {
                    if (res.code == 0) {
                        this.topupFlag = false
                        this.$message.success('充值成功');
                        this.getList()
                    } else {
                        this.$message.error(res.msg);
                    }
                });
        }
    },
};
</script>

<style lang="less" scoped>
.el-main {
    background: #fff;

    .total-num {
        display: flex;
        font-weight: bold;
        margin: 20px 0;

        div {
            margin-right: 40px;
        }
    }

    .sortList {
        white-space: nowrap;
        display: flex;
        align-items: center;
    }
}

.el-main {
    background: #fff;
    display: flex;
    flex-direction: column;

    .upload {
        margin-top: 20px;

        .el-button {
            position: relative;

            input {
                opacity: 0;
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                right: 0;
            }
        }
    }

    .btn {
        margin-bottom: 10px;

        .el-button {
            margin-right: 10px;
        }
    }
}
</style>
